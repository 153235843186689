import { useRouter } from "next/router";
import FooterMin from "@qoohoo/common/components/Footer/FooterMin";
import NavBar from "@qoohoo/common/components/NavBar/NavBarMin";
import LoginCard from "@qoohoo/common/components/LoginCard";
import { QoohooIcon } from "@qoohoo/common/library/Icons";
import { sendOtp, verifyOtp } from "utils/otp";
import cookiesToAuthHeader from "@qoohoo/common/utils/api/backend/cookiesToAuthHeader";
import routes from "routes";

const OTP_RESEND_TIMER_IN_SECONDS = 30;

//TODO: use users country by ip
const INITIAL_SELECTED_COUNTRY = {
  phone_code: "91",
  code: "IN",
  name: "India",
};

const Login = ({next}) => {
  const router = useRouter();

  const _verifyOtp = (phone, countryCode, otp, setIsLoading, whatsappOptIn) => {
    const onSuccess = () => {
      if(next) {
        if(next == routes.login.route) {
          // do nothing
        } else {
          router.push(next);
        }
      } else {
        router.push(routes.home.route);
      }
    };

    verifyOtp(phone, countryCode, otp, setIsLoading, whatsappOptIn, onSuccess);
  };

  return (
    <div className="w-full flex flex-col items-center min-h-screen bg-[#ffffff] sm:bg-[#F9FAFA]">
      <NavBar className="sm:hidden" />
      <div className="flex flex-col flex-1 items-center justify-center w-full">
        <div className="flex-1 w-full mt-16 sm:mt-0 sm:w-auto sm:flex-initial flex flex-col gap-7">
          <div className="hidden sm:block w-fit">
            <QoohooIcon />
          </div>
          <LoginCard
            className="sm:w-[450px] sm:h-fit"
            sendOtp={sendOtp}
            verifyOtp={_verifyOtp}
            otpResendTimer={OTP_RESEND_TIMER_IN_SECONDS}
            initialCountry={INITIAL_SELECTED_COUNTRY}
          />
        </div>
      </div>
      <FooterMin />
    </div>
  );
};

export default Login;

export async function getServerSideProps({query}){
  const { next } = query;

  return {
    props: {
      next: next || null,
    }
  }
}
