import toast from "react-hot-toast";
import { QOOHOO_WEBSITE_DOMAIN } from "constants/env";
import httpf from "./api/frontend/httpf";
import useSessionStore from "store/sessionStore";
import axios from "axios";
import getAuthMe from "helpers/getAuthMe";

export async function sendOtp(phone, countryCode, sendInWhatsapp, turnstileToken, setIsLoading) {
    if (phone.length <= 4 || !countryCode) {
      toast.error("Enter correct phone number");
      return false;
    }

    setIsLoading(true);
    return await httpf
      .post(`${QOOHOO_WEBSITE_DOMAIN}/api/auth/mobile/request`, {
        mobile: phone,
        countryCode: countryCode,
        sendInWhatsapp: sendInWhatsapp,
        turnstileToken: turnstileToken,
      })
      .then(() => {
        toast.success("OTP sent successfully.");

        return true;
      })
      .catch((err) => {
        console.error(err);

        const errors =
        err?.response?.data?.data?.message ||
        err?.response?.data?.message ||
        err?.response?.data?.data?.non_field_errors ||
        err?.response?.data?.non_field_errors ||
        err?.response?.data?.data;

        console.log(errors);

        if (errors && (!(errors instanceof Array) || errors.length > 0)) {
          toast.error(errors);
        } else {
          toast.error("Error sending OTP");
        }
      }).finally(()=>{
        setIsLoading(false);
      });
  }

  export async function verifyOtp(phone, countryCode, otp, setIsLoading, whatsapp_opt_in, onSuccess, onError) {

    const {setUserData, setUsername} = useSessionStore.getState();

    setIsLoading(true);
  
    if (otp.length < 6) {
      toast.error("Please enter 6 digit OTP");
      setIsLoading(false);
      return false;
    }
  
    const payload = {
      mobile: phone,
      countryCode,
      otp: otp,
      whatsapp_opt_in: whatsapp_opt_in || null,
    };
  
    return httpf
      .post(`${QOOHOO_WEBSITE_DOMAIN}/api/auth/verify-otp-creator`, payload, {withCredentials: true})
      .then(() => {
         return getAuthMe().then((res) => {
            console.log(res.data);
            setUserData({ ...res.data });
            setUsername(res.data.username);

            onSuccess && onSuccess();
            
            toast.success("OTP verified successfully");
            
            return res;
          })
          .catch((err) => {
            if(err.response.status === 401) {
              logout().then(()=>{
                setUserData({});
                setUsername("");
              });

              onError && onError();
            }
          });
      })
      .catch((err) => {
        console.log(err);
  
        onError && onError();

        const baseError = "Error verifying OTP! Please try again.";
        const error = err.response.data?.data?.[0] || baseError ; 

        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  
export async function logout(){
  await axios.get(`${QOOHOO_WEBSITE_DOMAIN}/api/auth/logout`, { withCredentials: true }).finally(() => {
    toast.error("Some error occurred, please login again.");
  });
}