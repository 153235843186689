import cookie from "cookie";

export default function cookiesToAuthHeader(req) {
  const cookies = cookie.parse(req.headers?.cookie || "");

  const authToken = cookies?.access_token ? `Bearer ${cookies?.access_token}` : null;

  return {
    Authorization: authToken,
    refresh_token: cookies?.refresh_token || "",
  };
}
