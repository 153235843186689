import cls from "classnames";
import React from "react";
import Link from "next/link";
import PhoneOtpForm from "./library/Form/PhoneOtpForm";

const LoginCard = (props) => {
  const {
    className,
    sendOtp,
    verifyOtp,
    otpResendTimer,
    initialCountry,
  } = props;

  return (
    <div
      className={cls(
        "flex flex-col font-nunito rounded-lg bg-[#FFFFFF] px-6 sm:p-12 border-0 sm:border border-[#E2E4FC] w-full h-full",
        className
      )}
    >
      <span className="text-2xl leading-normal text-[#16191B] font-bold mb-9">
        Login to dashboard
      </span>
      <PhoneOtpForm
        sendOtp={sendOtp}
        verifyOtp={verifyOtp}
        otpResendTimer={otpResendTimer}
        initialCountry={initialCountry}
      />
      <div className="w-full mt-20 sm:mt-40 text-center">
        Want to become a creator?{" "}
        <Link href="/creator-onboarding" className="text-[#384AF1] text-sm font-bold">
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default LoginCard;
