import cls from "classnames";
import React from "react";
import Link from "next/link";

const FooterMin = (props) => {
  const { className } = props;

  return <div className={cls("mt-auto flex items-center justify-center py-8 gap-8 bg-transparent", className)}>
    <Link href="https://qoohoo.in/pages/privacy" target="_blank" className="text-[#16191B] text-sm">Privacy Policy</Link>
    <Link href="https://qoohoo.in/pages/stdeula" target="_blank" className="text-[#16191B] text-sm">Terms &amp; Conditions</Link>
  </div>;
};

export default FooterMin;
