import { useCallback, useEffect, useMemo, useRef } from "react";
import cls from "classnames";
import { useState } from "react";
import { useCombobox } from "downshift";

import Modal from "../../../library/modals/Modal";
import CountryFlag from "../../CountryFlag"

import countries from "../../../constants/country/countries";
import { SearchIconAlt } from "../../../library/Icons";

// TODO1:selection state is not there in form. cross button is not there
// TODO2: opening and closing effect are very weird
const CountryCodeSelector = ({ className, selectedCountry, setCountry }) => {
  const [isPhoneInputModalOpen, setPhoneInputModalOpenState] = useState(false);
  const [filteredCountryList, setFilteredCountryList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(selectedCountry);

  const menuRef = useRef(null);

  function checkCountryNames(name, input) {
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!specialCharRegex.test(input)) {
      const pattern = `(${input})`;
      const regex = new RegExp(`${pattern}`, "g");
      return name.match(regex);
    }
  }

  function sortFilteredResults(a, b, input) {
    const relativeLengthA = a.length - input.length;
    const relativeLengthB = b.length - input.length;

    if (relativeLengthA < relativeLengthB) {
      return -1;
    } else if (relativeLengthA > relativeLengthB) {
      return 1;
    }

    return 0;
  }

  const memoizedFilterCountryFn = useMemo(() => {
    return (input) => {
      const filteredCountries = countries.filter((country) => {
        const countryName = country.name.toLowerCase();
        const result =
          countryName.startsWith(input) ||
          checkCountryNames(countryName, input) ||
          country.phone_code.toString().startsWith(input);

        return result;
      });

      if (input.length > 2) {
        const sortedFilteredCountryList = filteredCountries.sort((a, b) =>
          sortFilteredResults(a.name, b.name, input)
        );

        return sortedFilteredCountryList;
      }

      return filteredCountries;
    };
  }, [filteredCountryList]);

  function handleCountrySelect(country) {
    setCurrentCountry(country);
    setPhoneInputModalOpenState(false);
  }

  useEffect(() => {
    if (setCountry) {
      setCountry(currentCountry);
    }
  }, [currentCountry]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    inputValue,
    highlightedIndex,
  } = useCombobox({
    items: [...filteredCountryList, ...countries],
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          const { selectedItem } = changes;
          if (selectedItem) {
            handleCountrySelect(selectedItem);
          }

          return {
            ...changes,
            isOpen: false, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: "", // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "", // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      const input = inputValue.toLowerCase();

      const resultantCountryList = memoizedFilterCountryFn(input);

      setFilteredCountryList(resultantCountryList);
    },
  });

  const filteredCountryListLength = filteredCountryList.length;
  const noResultFound = inputValue !== "" && filteredCountryListLength === 0;

  const closePhoneInputModal = useCallback(() => {
    setPhoneInputModalOpenState(false);
  }, [setPhoneInputModalOpenState]);

  useEffect(() => {
    // scrolls to the filtered countries list based on input change
    if (menuRef.current) {
      menuRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [filteredCountryListLength]);

  return (
    <>
      <div
        className={cls("w-fit h-fit relative select-none pl-2 pr-1 flex gap-[2px] items-center",className)}
        value={selectedCountry.phone_code}
        onClick={(e) => {
          e.preventDefault();
          setPhoneInputModalOpenState(true);
        }}
      >
        <CountryFlag className="w-4 mr-1" countryCode={selectedCountry.code} />
        +{selectedCountry.phone_code}
        <svg
          className="ml-1"
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 4L6.5 8L10 4H3Z"
            fill="#22272B"
            fillOpacity="0.72"
          />
        </svg>
      </div>
      <Modal zIndex={"z-[51]"} isOpen={isPhoneInputModalOpen} closeModal={closePhoneInputModal}>
        <div className="fixed inset-0 m-6 sm:w-min sm:m-0 sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 sm:h-fit">
          <div
            className={cls(
              "flex flex-col h-full sm:-translate-y-4 sm:w-[360px] sm:h-[560px] overflow-y-auto text-left align-middle sm:shadow-xl rounded-md",
              "bg-white"
            )}
          >
            <div
              className={cls(
                "flex items-center pl-3 py-[14px]",
                "bg-white shadow-[0px_4px_20px_-4px_rgba(0,0,0,.2)]"
              )}
              {...getComboboxProps({}, { suppressRefError: true })}
            >
              <div className="bg-white">
                <SearchIconAlt
                  color="#000"
                  opacity={0.72}
                  className="w-5 h-5"
                />
              </div>
              <input
                type="text"
                name="country-code"
                placeholder="Search a country"
                className={cls(
                  "px-2 appearance-none  placeholder-opacity-25 focus:ring-0 outline-none !border-none !rounded-md !text-sm !w-full",
                  "!bg-white text-black"
                )}
                {...getInputProps({}, { suppressRefError: true })}
              />
            </div>
            <div
              className="flex flex-col mt-2 sm:max-h-[504px] overflow-y-scroll scrollbar-hide"
              {...getMenuProps({ ref: menuRef }, { suppressRefError: true })}
            >
              {noResultFound && (
                <div className="py-6 text-center text-gray-600">
                  No results for "{inputValue}"
                </div>
              )}

              {!noResultFound &&
                inputValue !== "" &&
                filteredCountryList.map((country, index) => (
                  <button
                    key={`${country.name}${country.phone_code}`}
                    className={cls(
                      "flex justify-between items-center px-3 py-3  text-sm border-b border-white/5 hover:bg-black hover:bg-opacity-20",
                      "text-black",
                      filteredCountryListLength - 1 === index
                        ? "border-b-0"
                        : "",
                      highlightedIndex === index ? "bg-black bg-opacity-20" : ""
                    )}
                    {...getItemProps({ country, index })}
                  >
                    <div>{country.name}</div>
                    <div>+{country.phone_code}</div>
                  </button>
                ))}
              <div
                className={cls(
                  "relative text-xs px-3 py-3",
                  "text-black/60"
                )}
              >
                All Countries
                <div
                  className={cls(
                    "absolute top-1/2 right-0 ml-auto w-2/3 h-[1px] ",
                    "bg-black/5"
                  )}
                />
              </div>
              {countries.map((country, index) => {
                const updatedIndex = filteredCountryListLength + index;
                return (
                  <button
                    key={`${country.name}${country.phone_code}${updatedIndex}`}
                    className={cls(
                      "flex justify-between items-center px-3 py-3  text-sm border-b border-white/5 hover:bg-black hover:bg-opacity-20",
                      "text-black",
                      highlightedIndex === updatedIndex
                        ? "bg-black bg-opacity-20"
                        : ""
                    )}
                    {...getItemProps({
                      country,
                      index: updatedIndex,
                    })}
                  >
                    <div>{country.name}</div>
                    <div>+{country.phone_code}</div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CountryCodeSelector;
