import cls from "classnames";
import { useState } from "react";

export function InputFieldWrapper(props) {
  const { className, label, description, error, children } = props;

  const showError = error && error.isBlur;

  return (
    <div className={cls("flex flex-col items-start", className)}>
      {label && <div className="mb-1 font-semibold text-sm">{label}</div>}

      {children}

      {!showError && description && (
        <div className="mt-1 text-[13px] text-left text-[#8F8F8F]">
          {description}
        </div>
      )}
      {showError ? (
        <div className="mt-1 text-[13px] text-red-500 text-left">
          {error.description}
        </div>
      ) : null}
    </div>
  );
}

export default function InputField(props) {
  const {
    className,
    inputClass,
    primaryColor="#384AF1",
    name,
    type,
    placeholder,
    value,
    onChange,
    onKeyPress,
    onBlur,
    onFocus,
    autoComplete = "true",
    required,
    disabled,
    autoFocus,
    addonBefore,
    addonAfter,
  } = props;

  const [isFocused, setIsFocused] = useState();

  const _onBlur = (e) => {
    setIsFocused(false);
    onBlur && onBlur(e);
  };

  const _onFocus = (e) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  return (
    <div
      className={cls(
        "w-full h-11 text-[#22272B] rounded border overflow-hidden flex items-center",
        className
      )}
      style={{
        borderColor: isFocused ? primaryColor: "#D5D8DC"
      }}
    >
      {addonBefore && addonBefore()}
      <input
        className={cls(
          "bg-transparent h-full px-3 text-sm flex-1 min-w-0",
          "placeholder-[#22272B] placeholder-opacity-20",
          "outline-none focus:outline-none disabled:cursor-not-allowed",
          inputClass
        )}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onKeyPress={onKeyPress}
        onChange={onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
      />
      {addonAfter && addonAfter()}
    </div>
  );
}
