import cls from "classnames";
import { useEffect } from "react";
import Spinner from "../../../library/loader/Spinner";
import InputField, { InputFieldWrapper } from "./InputField";

const otpLength = 6;

const OtpInputField = (props) => {
  const { otp, onChange, onSubmit, isLoading, primaryColor="#384AF1" } = props;

  useEffect(() => {
    if(otp.length === otpLength && !isLoading) {
      onSubmit();
    }
  }, [otp]);

  return (
    <InputField
      inputClass={cls("!text-xl font-semibold !leading-none tracking-[1.5rem]")}
      type="text"
      placeholder="••••••"
      pattern="\d*"
      value={otp}
      autoFocus={true}
      primaryColor={primaryColor}
      autoComplete="off"
      disabled={false}
      onChange={onChange}
      addonAfter={() => {
        const isSubmitDisabled = otp.length < otpLength;
        return (
          <div className="flex items-center justify-center px-3 h-full flex-shrink-0">
            {isLoading ? (
              <Spinner color={primaryColor} className="!h-6 !w-6 flex-shrink-0" />
            ) : (
              <button
                className={cls(
                  "font-extrabold text-sm cursor-pointer flex-shrink-0 disabled:cursor-not-allowed",
                  isSubmitDisabled && "text-opacity-40"
                )}
                style={{color: primaryColor}}
                disabled={isSubmitDisabled}
                onClick={onSubmit}
              >
                VERIFY
              </button>
            )}
          </div>
        );
      }}
    />
  );
};

export default OtpInputField;
