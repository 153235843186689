import cls from "classnames";
import { useMemo, useState, useRef } from "react";
import toast from "react-hot-toast";
import Turnstile from "react-turnstile";
import { NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY } from "../../../constants/env";
import useCountdown from "../../../hooks/useCountdown";
import { ButtonInvert, ButtonPrimary } from "../Button";

import InputField, { InputFieldWrapper } from "./InputField";
import CountryCodeSelector from "./CountryCodeSelector";
import OtpInputField from "./OtpInputField";
import { EditIcon } from "../../../library/Icons";

const PhoneOtpForm = (props) => {
    const { verifyOtp, sendOtp, otpResendTimer, initialCountry, primaryColor="#384AF1", invertColor="#EDF3FE"} = props;

    const [timer, resetTimer] = useCountdown(otpResendTimer);
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState(initialCountry);
    const [isLoading, setIsLoading] = useState(false);
    const [isSendingOTPViaSMS, setIsSendingOTPViaSMS]=useState(false);
    const [isSendingOTPViaWhatsapp, setIsSendingOTPViaWhatsapp]=useState(false);
    const [otp, setOtp] = useState("");
    const [isOTPScreen, setIsOTPScreen] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState(null);

    const turnstileRef = useRef(null);
  
    const saveTurnstileToken = (token) => {
      setTurnstileToken(token);
    }

    const onTurnstileError = () => {
      setTurnstileToken("dummy-token");
    }

    function resetTurnstile () {
      setTurnstileToken("");
      window?.turnstile?.reset(turnstileRef.current);
    }

    const _verifyOtp = () => {
      const enableWhatsappOptIn = true;
      verifyOtp(phone, country.phone_code, otp, setIsLoading, enableWhatsappOptIn);
    };
  
    const sendSmsOTP = async () => {
      const res = await sendOtp(phone, country.phone_code, false, turnstileToken, setIsSendingOTPViaSMS);
  
      if (res) {
        setIsOTPScreen(true);
        resetTimer();
      }
      
      resetTurnstile();
    };
    const sendWhatsAppOTP = async () => {
      const res = await sendOtp(phone, country.phone_code, true, turnstileToken, setIsSendingOTPViaWhatsapp);
  
      if (res) {
        setIsOTPScreen(true);
        resetTimer();

      }
      
      resetTurnstile();
    };
  
    const editPhone = () => {
      setIsOTPScreen(false);
      setOtp("");
    };
  
    const handleOtpChange = (e) => {
      e.preventDefault();
      let otpInputValue = e.target.value;
  
      if (otpInputValue.length <= 6) {
        setOtp(otpInputValue.replace(/[^0-9]/, ""));
      }
    };
  
    const onPhoneChange = (e) => {
      setPhone(e.target.value.replace(/[^0-9]/, ""));
    };

    const isSendingOtp = isSendingOTPViaSMS || isSendingOTPViaWhatsapp;
  
    const isSubmitDisabled = !turnstileToken || isSendingOtp || isLoading || !phone || (isOTPScreen && timer != 0);
  
    const rowLabel = useMemo(() => {
      if (timer > 0 && isOTPScreen) {
        return `Resend OTP in ${timer}s`;
      } else {
        return "Get OTP via:";
      }
    }, [timer, isOTPScreen]);
  
    const renderOtpField = () => {
      const fieldLabel = (
        <div className="flex items-center gap-1">
          <span className="text-sm leading-5 text-[#16191B] font-semibold select-none">
            OTP is sent to you on
          </span>
          <div
            className="font-semibold text-sm leading-5 text-[#16191B] underline flex items-center cursor-pointer"
            onClick={editPhone}
          >
            <span>{phone}</span>
            <div className="cursor-pointer ml-1">
              <EditIcon />
            </div>
          </div>
        </div>
      );
  
      return (
        <InputFieldWrapper label={fieldLabel}>
          <OtpInputField
            otp={otp}
            onChange={handleOtpChange}
            onSubmit={_verifyOtp}
            isLoading={isLoading}
            primaryColor={primaryColor}
          />
        </InputFieldWrapper>
      );
    };
  
    const renderPhoneField = () => {
      return (
        <InputFieldWrapper label="Phone number">
          <InputField
            type="tel"
            value={phone}
            onChange={onPhoneChange}
            placeholder="WhatsApp number"
            primaryColor={primaryColor}
            addonBefore={() => (
              <CountryCodeSelector
                setCountry={setCountry}
                selectedCountry={country}
                className="after:content-[''] after:h-full after:w-px after:block after:absolute after:right-0 after:bg-[#D5D8DC] after:top-1/2 after:-translate-y-1/2"
              />
            )}
          />
        </InputFieldWrapper>
      );
    };
  
    const buttonsRow = (
      <div className="mt-3 flex flex-col">
        <span className="text-[13px] leading-[1.125rem] text-[#767676] text-left font-semibold">
          {rowLabel}
        </span>
        <div className="flex gap-3 mt-2">
          <ButtonInvert
            onClick={sendWhatsAppOTP}
            className={cls("flex-1 !text-sm")}
            loaderColor={primaryColor}
            style={{ backgroundColor: invertColor, color: primaryColor }}
            disabled={isSubmitDisabled}
            isLoading={isSendingOTPViaWhatsapp}
            doOpacityForDisabled
          >
            WhatsApp
          </ButtonInvert>
          <ButtonPrimary
            onClick={sendSmsOTP}
            className={cls("flex-1 !text-sm")}
            loaderColor={invertColor}
            style={{ backgroundColor: primaryColor, color: invertColor }}
            disabled={isSubmitDisabled}
            isLoading={isSendingOTPViaSMS}
            doOpacityForDisabled
          >
            SMS
          </ButtonPrimary>
        </div>
      </div>
    );
  
    return (
      <>
        {isOTPScreen ? renderOtpField() : renderPhoneField()}
        {buttonsRow}
        <Turnstile
          userRef={turnstileRef}
          retry="auto"
          refreshExpired="auto"
          size="invisible"
          execution="render"
          sitekey={NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY} 
          onVerify={saveTurnstileToken} 
          onError={onTurnstileError}
        />

      </>
    );
  };


export default PhoneOtpForm;