import cls from "classnames";
import React from "react";
import Link from "next/link";
import { QoohooIcon } from "../../library/Icons";
import { WEBSITE_DOMAIN } from "../../constants/env";

const NavBarMin = (props) => {
  const { className } = props;
  return (
    <div
      className={cls(
        "w-full py-3 px-6 flex items-center bg-transparent border-b border-[#D5D8DC4D]",
        className
      )}
    >
      <Link href={`${WEBSITE_DOMAIN}`}>
        <QoohooIcon />
      </Link>
    </div>
  );
};

export default NavBarMin;
